import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'icon'];
  static classes = ['hidden'];

  connect() {
    console.log("Connected to password visibility controller");
  }


  toggle(event) {
    event.preventDefault();

    const input = event.target.closest('button').previousElementSibling;
    const isPassword = input.type === 'password';
    input.type = isPassword ? 'text' : 'password';

    const icons = event.target.closest('button').querySelectorAll('[data-password-visibility-target="icon"]');
    icons.forEach((icon) => icon.classList.toggle(this.hiddenClass));
  }
}
