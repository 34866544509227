import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'counter']
  static values = { countdown: Boolean }

  connect() {
    console.log('Connected to character counter controller')
    if (this.isTrixEditor()) {
      this.waitForTrixEditor().then(() => this.addEventListeners())
    } else {
      this.addEventListeners()
    }
  }

  disconnect() {
    this.inputTarget.removeEventListener(this.inputEvent, this.update)
  }

  addEventListeners() {
    this.update()
    this.inputTarget.addEventListener(this.inputEvent, this.update)
  }

  update = () => {
    this.counterTarget.innerHTML = this.calculateCount()
  }

  calculateCount() {
    const currentLength = this.getCurrentInputLength()
    const maxLength = this.maxLength
    return `${currentLength} / ${maxLength}`
  }

  getCurrentInputLength() {
    if (this.isTrixEditor()) {
      return this.inputTarget.editor.getDocument().toString().trim().length
    }
    return this.inputTarget.value.trim().length
  }

  get maxLength() {
    return this.inputTarget.getAttribute('data-max-length') || this.inputTarget.maxLength || 0
  }

  get inputEvent() {
    return this.isTrixEditor() ? 'trix-change' : 'input'
  }

  isTrixEditor() {
    return this.inputTarget.tagName === 'TRIX-EDITOR'
  }

  waitForTrixEditor() {
    return new Promise((resolve) => {
      if (this.inputTarget.editor) {
        resolve()
      } else {
        this.inputTarget.addEventListener('trix-initialize', () => resolve(), { once: true })
      }
    })
  }
}
