import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["password", "confirmation", "error"];

  connect() {
    console.log("Connected to password match controller");
    this.validate = this.validate.bind(this);
    this.passwordTarget.addEventListener("input", this.validate);
    this.confirmationTarget.addEventListener("input", this.validate);
  }

  validate() {
    const password = this.passwordTarget.value;
    const confirmation = this.confirmationTarget.value;

    if (password !== confirmation && confirmation !== "") {
      this.errorTarget.classList.remove("hidden");
    } else {
      this.errorTarget.classList.add("hidden");
    }
  }

  validateBeforeSubmit(event) {
    const password = this.passwordTarget.value;
    const confirmation = this.confirmationTarget.value;

    if (password !== confirmation) {
      event.preventDefault();
      this.errorTarget.classList.remove("hidden");
    }
  }
}
