import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dialog", "close"]

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.closeTarget.addEventListener("click", (event) => {
      event.preventDefault();

      this.dialogTarget.remove();
    });
  }
}
