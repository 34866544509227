import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    copy(event) {
        event.preventDefault();
        const url = this.element.dataset.url;

        navigator.clipboard.writeText(url)
            .then(() => {
                console.log("Link copied to clipboard");
                // Optionally, show a success message here
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });

        // Hide the dropdown after copying the link
        const dropdownElement = this.element.closest('[data-controller="dropdown"]');
        const dropdownController = this.application.getControllerForElementAndIdentifier(
            dropdownElement,
            'dropdown'
        );
        dropdownController.hide({ target: document.body });
    }
}
