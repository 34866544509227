import { Controller } from "@hotwired/stimulus"
import DOMPurify from 'dompurify';

export default class extends Controller {
    connect() {
        document.addEventListener('trix-before-paste', function (e) {
            if (e.paste.hasOwnProperty('html')) {
                let cleanHTML = DOMPurify.sanitize(e.paste.html, {
                    ALLOWED_TAGS: ['a', 'br', 'p', 'strong', 'em', 'b', 'i', 'ul', 'ol', 'li', 'blockquote'],
                    ALLOWED_ATTR: ['href', 'rel'],
                    FORBID_ATTR: ['style']
                });

                // Add rel="nofollow" to <a> tags
                let div = document.createElement("div");
                div.innerHTML = cleanHTML;

                Array.from(div.getElementsByTagName("a")).forEach(function(node) {
                    node.setAttribute("rel", "nofollow");
                });

                e.paste.html = div.innerHTML;
            }
        });
    }
}
