import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['address']
  static values = { apiKey: String }

  connect() {
    console.log('Connected to places controller')
    this.placeSelected = false

    this.loadGoogleMapsApi().then(() => {
      this.initAutocomplete()
    }).catch(error => {
      console.error('Failed to load the Google Maps JavaScript API:', error)
    })

    // Disable Enter key submission
    this.addressTarget.addEventListener('keydown', this.preventEnterSubmit.bind(this))
  }

  async loadGoogleMapsApi() {
    if (window.google && google.maps) {
      return Promise.resolve();
    }

    if (window.loadingGoogleMapsApi) {
      return window.loadingGoogleMapsApi;
    }

    window.loadingGoogleMapsApi = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const params = new URLSearchParams({
        key: this.apiKeyValue,
        v: 'weekly',
        libraries: 'places',
        callback: 'initGoogleMaps'
      });

      script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
      script.async = true;
      script.defer = true;
      script.onerror = () => reject(new Error('Google Maps API failed to load'));

      window.initGoogleMaps = () => {
        resolve();
        window.initGoogleMaps = null;
      };

      document.head.appendChild(script);
    });

    return window.loadingGoogleMapsApi;
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, {
      types: ['(cities)'],
      fields: ['address_components'],
    })

    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    this.addressTarget.addEventListener('input', this.onInput.bind(this))
    this.addressTarget.addEventListener('blur', this.onBlur.bind(this))
  }

  preventEnterSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  placeChanged() {
    console.log('Place changed');
    this.placeSelected = true;
    const place = this.autocomplete.getPlace();
    const addressComponents = place.address_components;

    if (addressComponents) {
      const formattedAddress = this.formatAddressComponents(addressComponents);
      this.setAddressComponents(formattedAddress);

      // Manually trigger the change event
      this.fireAutosaveEvent();
    }
  }

  fireAutosaveEvent() {
    const event = new Event('change', { bubbles: true });
    this.addressTarget.dispatchEvent(event);
  }

  onInput() {
    this.placeSelected = false
  }

  onBlur() {
    if (!this.placeSelected && this.addressTarget.value.trim() !== '') {
      this.addressTarget.value = ''
    }
  }

  setAddressComponents(address) {
    console.log('API response 2:', address)

    // Normalize city name for San Francisco
    if (address.locality === 'SF') {
      address.locality = 'San Francisco'
    }
    if (address.locality && address.locality.includes('Washington')) {
      address.locality = 'Washington'
    }

    const city = address.locality || address.colloquial_area || ''
    const state = address.administrative_area_level_1 || ''
    const abbreviated_country = address.country || ''
    const formattedAddress = `${city}, ${state}, ${abbreviated_country}`


    this.addressTarget.value = formattedAddress.trim();
  }


  formatAddressComponents(addressComponents) {
    const data = {}
    addressComponents.forEach((component) => {
      const type = component.types[0]
      data[type] = component.short_name
    })
    return data
  }
}
