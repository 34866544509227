import { Controller } from "@hotwired/stimulus";
import intlTelInput from 'intl-tel-input';

const utilsScriptSrc = 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js';

// Connects to data-controller="phone-input"
export default class extends Controller {
  static targets = ['input', 'hidden'];
  static values = {
    country: { type: String, default: 'US' },
    switch: { type: Boolean, default: true },
    uniqueId: String
  }

  connect() {
    console.log("PhoneInputController connected");
    this.inputTarget.id = `phone-input-${this.uniqueIdValue}`;
    this.hiddenTarget.id = `phone-hidden-${this.uniqueIdValue}`;

    this.loadUtilsScript()
        .then(() => {
          this.initializeIntlTelInput();
          this.initializeFormatter();
        })
        .catch(error => console.error("Error loading intlTelInput utils script:", error));
  }

  loadUtilsScript() {
    return new Promise((resolve, reject) => {
      if (window.intlTelInputUtils) {
        // If the script is already loaded, resolve immediately
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = utilsScriptSrc;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      }
    });
  }

  initializeIntlTelInput() {
    this.iti = intlTelInput(this.inputTarget, {
      allowDropdown: this.switchValue,
      customContainer: 'w-100',
      initialCountry: this.countryValue,
      preferredCountries: ['us', 'ca', 'gb', 'mx', 'br'],
      separateDialCode: true,
      utilsScript: utilsScriptSrc,
    });

    this.inputTarget.addEventListener("countrychange", () => this.handleChangePattern());
  }

  initializeFormatter() {
    const initialCountry = this.iti.getSelectedCountryData().iso2;
    const pattern = this.getPatternForCountry(initialCountry);

    this.formatter = new Formatter(this.inputTarget, {
      pattern: pattern,
    });
  }

  getPatternForCountry(countryCode) {
    try {
      const numberFormat = intlTelInputUtils.numberFormat;
      const exampleNumber = intlTelInputUtils.getExampleNumber(countryCode, true, numberFormat.INTERNATIONAL);
      return this.extractPatternFromExampleNumber(exampleNumber);
    } catch (error) {
      console.error("Error fetching pattern for country:", countryCode, error);
      return countryCode === 'us' ? '({{9}}{{9}}{{9}}) {{9}}{{9}}{{9}}-{{9}}{{9}}{{9}}{{9}}' : '{{9999999999999999999999}}';
    }
  }

  extractPatternFromExampleNumber(exampleNumber) {
    return exampleNumber.replace(/\d/g, '{{9}}');
  }

  updateCountry({ detail: { content }}) {
    const newCountry = content.pop();
    this.iti.setCountry(newCountry);
  }

  validate() {
    if (this.inputTarget.value.length === 0) {
      this.inputTarget.setCustomValidity('');
      this.hiddenTarget.value = '';
      return;
    }

    if (!this.iti.isPossibleNumber()) {
      const error = this.iti.getValidationError();
      const errorMsg = this.getValidationErrorMessage(error);
      console.log("Error:", errorMsg);
      this.inputTarget.setCustomValidity(errorMsg);
    } else {
      this.inputTarget.setCustomValidity('');
      this.hiddenTarget.value = this.iti.getNumber();
    }

    this.inputTarget.reportValidity();
  }

  getValidationErrorMessage(error) {
    switch(error) {
      case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
        return "Phone number has invalid country code";
      case intlTelInputUtils.validationError.TOO_SHORT:
        return "Phone number is too short";
      case intlTelInputUtils.validationError.TOO_LONG:
        return "Phone number is too long";
      default:
        return "Invalid phone number";
    }
  }

  handleChangePattern() {
    const { iso2: prefix } = this.iti.getSelectedCountryData();
    const newPattern = this.getPatternForCountry(prefix);
    this.formatter.resetPattern(newPattern);
  }
}
