import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { currentDate: String, displayForm: Boolean };
  static targets = ["scheduleDate", "scheduleForm", "dateTimeError"];

  connect() {
    console.log("Hello, Stimulus!", this.element);

    if (this.displayFormValue) {
      this.displayForm();
    }

    this.monthElem = this.element.querySelector("select[name='month']");
    this.dayElem = this.element.querySelector("select[name='day']");
    this.yearElem = this.element.querySelector("select[name='year']");
    this.timeElem = this.element.querySelector("input[name='schedule[time]']");

    this.setCurrentDate();
    this.setDays();

    this.dayElem.addEventListener("change", this.handleDateChange.bind(this));
    this.monthElem.addEventListener("change", this.handleDateChange.bind(this));
    this.yearElem.addEventListener("change", this.handleDateChange.bind(this));
    this.timeElem.addEventListener("change", this.handleDateChange.bind(this));
  }

  setCurrentDate() {
    const date = new Date(this.currentDateValue);

    this.monthElem.value = date.getMonth() + 1;
    this.dayElem.value = date.getDate();
    this.yearElem.value = date.getFullYear();
    this.timeElem.value = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  handleDateChange(event) {
    const { target } = event;

    if (target.name === "month") {
      this.setDays(Number(target.value));
    }

    const methods = {
      month: () => this.updateDate("month", target.value),
      day: () => this.updateDate("day", target.value),
      year: () => this.updateDate("year", target.value),
      "schedule[time]": () => this.updateDate("time", target.value),
    };

    console.log("target", target);
    console.log(target.name, target.value);

    methods[target.name]();
    this.validateFutureDate();  // Call the validation after date update
  }

  setDays(month) {
    const currentMonth = month ?? Number(this.monthElem.value);
    const year = this.yearElem.value;
    const currentDay = this.dayElem.value;
    const numberOfDays = new Date(year, currentMonth, 0).getDate();
    const days = [...Array(numberOfDays).keys()].map((day) => day + 1);

    this.dayElem.innerHTML = "";

    days.forEach((day) => {
      const option = document.createElement("option");
      option.value = day;
      option.text = day;

      this.dayElem.appendChild(option);
    });

    this.updateDayElemValue(days.includes(Number(currentDay)) && currentDay);
  }

  updateDayElemValue(value) {
    if (value) {
      this.dayElem.value = value;
      return;
    }

    this.dayElem.value = 1;
    this.updateDate("day", 1);
  }

  updateDate(method, value) {
    const currentDay = this.dayElem.value;
    const currentMonth = this.monthElem.value;
    const currentYear = this.yearElem.value;
    const currentTime = this.timeElem.value;
    const [hours, minutes] = currentTime.split(":");

    const localDate = new Date(currentYear, currentMonth - 1, currentDay, hours, minutes);

    const formattedDate = `${currentYear}-${String(currentMonth).padStart(2, '0')}-${String(currentDay).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

    this.scheduleDateTarget.value = formattedDate;

    this.triggerSave();
  }

  validateFutureDate() {
    const selectedDate = new Date(this.scheduleDateTarget.value);
    const currentDate = new Date();
    const minFutureDate = new Date(currentDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours from now

    // Format minFutureDate for display in the error message in Eastern Time (ET)
    const options = {
      timeZone: 'America/New_York',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    const formattedMinDate = minFutureDate.toLocaleString("en-US", options);

    if (selectedDate <= currentDate || selectedDate < minFutureDate) {
      this.dateTimeErrorTarget.textContent = `Scheduled date and time must be later than ${formattedMinDate} ET (UTC-04:00)`;
      this.scheduleDateTarget.classList.add("border-red-500");

      // Clear fields if the date is invalid
      this.clearFields();
    } else {
      this.dateTimeErrorTarget.textContent = "";
      this.scheduleDateTarget.classList.remove("border-red-500");
    }
  }

  clearFields() {
    this.scheduleDateTarget.value = "";
  }

  displayForm() {
    this.scheduleFormTarget.classList.remove("hidden");
    this.scheduleFormTarget.classList.add("flex");
  }

  hideForm() {
    this.scheduleFormTarget.classList.remove("flex");
    this.scheduleFormTarget.classList.add("hidden");

    this.clearFields();
    this.triggerSave();
  }

  triggerSave() {
    const event = new Event("keyup", { bubbles: true, cancelable: true, view: window });
    this.scheduleDateTarget.dispatchEvent(event);
  }
}
