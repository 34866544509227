import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropZone", "fileField", "logoContainer", "logo", "uploadIcon", "uploadInstructions"]
  static values = { url: String, submitOnChange: Boolean }

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.start();

    this.dropZoneTarget.addEventListener("dragover", this.preventDefaults, false);
    this.dropZoneTarget.addEventListener("drop", this.handleDrop.bind(this), false);
    this.dropZoneTarget.addEventListener("click", this.openFilePicker.bind(this), false);
    this.fileFieldTarget.addEventListener("change", this.onChange.bind(this), false);
  }

  start(){
    if (this.urlValue) {
      this.logoTarget.src = this.urlValue;

      this.displayLogo();
    }
  }

  displayLogo() {
    if(!this.logoContainerTarget.classList.contains('hidden')) return;

    this.logoContainerTarget.classList.remove('hidden');
    this.uploadIconTarget.classList.add('hidden');

    if (this.hasUploadInstructionsTarget) {
      this.uploadInstructionsTarget.classList.add('hidden');
    }
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  openFilePicker(e) {
    this.preventDefaults(e);

    this.fileFieldTarget.click();
  }

  onChange(e) {
    this.preventDefaults(e);

    this.logoTarget.src = URL.createObjectURL(e.target.files[0]);

    this.displayLogo();

    if (this.submitOnChangeValue) {
      this.fileFieldTarget.closest("form").requestSubmit(); // Submit the form
    }

    return e;
  }

  handleDrop(e) {
    this.preventDefaults(e);

    this.fileFieldTarget.files = e.dataTransfer.files;
    this.logoTarget.src = URL.createObjectURL(e.dataTransfer.files[0]);

    this.displayLogo();

    if (this.submitOnChangeValue) {
      this.fileFieldTarget.closest("form").requestSubmit(); // Submit the form
    }
  }
}
